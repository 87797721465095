// import { useRef } from 'react';
import { useState, useEffect, useRef } from 'react';
import { PageInfo } from "./App";
import { useHeading } from './HeadingContext';

type activeFunc = (page: string) => void;
type PageProps = {
    activePageId: string;
    allPages: PageInfo[];
    setActivePageId: activeFunc;
}

const activeLinkColor = "border-secondary";
const inactiveLinkColor = "border-transparent";

// const logo = "/favicon.ico";

export function Heading({ activePageId, allPages, setActivePageId }: PageProps) {
    const headingRef = useRef<HTMLDivElement>(null);
    const { HeadingHeight, setHeadingHeight, setMinHeight } = useHeading()!;
    const [backgroundWhite, setBackgroundWhite] = useState(true);
    const [isVerticalNavVisible, setIsVerticalNavVisible] = useState(false);
    

    useEffect(() => {
        const height = headingRef.current?.offsetHeight ?? 0;
        // Assuming you have a state or method to set the height in your context provider
        setHeadingHeight(height);
        setMinHeight(`${window.innerHeight - height}px`);
    }, []);

    useEffect(() => {
        const page = document.getElementsByClassName(activePageId)[0];
        if (page) {
            setBackgroundWhite(window.getComputedStyle(page!).backgroundColor === "rgb(255, 255, 255)")
        }
    }, [activePageId])
    
    return (
        <>
            <div className='w-full fixed right-0 top-0 flex sm:hidden justify-end pt-2 px-2 z-50'>
                <button className='flex justify-center items-center bg-white rounded-md shadow-lg p-1'
                        onClick={() => {setIsVerticalNavVisible(true)}}>
                    <svg className="w-6 sidebar-button z-1000" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </button>
            </div>
            {/* {isVerticalNavVisible && ( */}
                <nav className={`fixed right-0 top-0 h-full w-[30vw] min-w-24 bg-off_white z-50 shadow-lg ${isVerticalNavVisible ? 'vnav-in' : 'vnav-out'}`}>
                    <div className='pl-1 pt-1'>
                        <button className='flex justify-center items-center bg-white rounded-md shadow-lg p-1 mb-4'
                                onClick={() => {setIsVerticalNavVisible(false)}}>
                            <svg className="w-6 sidebar-button" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <div className="my-1 flex flex-col justify-between space-y-3 sm:space-y-6 md:space-y-9 lg:space-y-12 text-secondary">
                            {PageProcess(activePageId, allPages[1], setActivePageId, "normal", HeadingHeight)}
                            {PageProcess(activePageId, allPages[2], setActivePageId, "normal", HeadingHeight)}
                            {PageProcess(activePageId, allPages[3], setActivePageId, "normal", HeadingHeight)}
                            <div className='mx-auto'>
                                {PageProcess(activePageId, allPages[4], setActivePageId, "button", HeadingHeight)}
                            </div>
                        </div>
                    </div>
                </nav>
            {/* )} */}
            {/* <nav ref={headingRef} id='horizontal-navbar' className={`sticky top-0 w-full z-50 ${backgroundWhite ? "bg-transparent" : "bg-transparent"} min-h-10 h-[10vh] xl:h-[8vh] navbar hidden sm:flex flex-row justify-between items-center shadow-lg`}>
                {PageProcess(activePageId, allPages[0], setActivePageId, "logo", HeadingHeight)}

                <div className="mx-2 my-1 flex flex-row justify-between space-x-3 sm:space-x-6 md:space-x-9 lg:space-x-12 text-secondary">
                    {PageProcess(activePageId, allPages[1], setActivePageId, "normal", HeadingHeight)}
                    {PageProcess(activePageId, allPages[2], setActivePageId, "normal", HeadingHeight)}
                    {PageProcess(activePageId, allPages[3], setActivePageId, "normal", HeadingHeight)}
                    {PageProcess(activePageId, allPages[4], setActivePageId, "button", HeadingHeight)}
                </div>
            </nav> */}
            <nav ref={headingRef} id='horizontal-navbar' className={`sticky top-0 w-full z-50 ${backgroundWhite ? "bg-off_white" : "bg-white"} min-h-10 h-[10vh] xl:h-[8vh] navbar hidden sm:flex flex-row justify-between items-center shadow-lg`}>
                {PageProcess(activePageId, allPages[0], setActivePageId, "logo", HeadingHeight)}

                <div className="mx-2 my-1 flex flex-row justify-between space-x-3 sm:space-x-6 md:space-x-9 lg:space-x-12 text-secondary">
                    {PageProcess(activePageId, allPages[1], setActivePageId, "normal", HeadingHeight)}
                    {PageProcess(activePageId, allPages[2], setActivePageId, "normal", HeadingHeight)}
                    {PageProcess(activePageId, allPages[3], setActivePageId, "normal", HeadingHeight)}
                    {PageProcess(activePageId, allPages[4], setActivePageId, "button", HeadingHeight)}
                </div>
            </nav>
        </>
    )
}

const GetToPage = (pageId: string, setActivePageId: activeFunc, scrollAdjustment: number) => {
    // const horizontalNavbar = document.getElementById("horizontal-navbar");

    document.getElementById(`${pageId}-header-holder`)?.scrollIntoView({ behavior: 'smooth' })

    console.log(scrollAdjustment);
    setActivePageId(pageId)
}


const PageProcess = ( activePageId : string, page : PageInfo, setActivePageId : activeFunc, type: string, scrollAdjustment: number ) => {
    let isActive = activePageId == page.id;
    return (
    <div 
        style={{
            display: 'flex', 
            alignItems: 'center',
            cursor: "pointer"
        }}
        onClick={()=>{
            GetToPage(page.id, setActivePageId, scrollAdjustment)
    }}>
        {type === "logo" ? <div className="title py-0 px-5">ELK</div> : 
        type === "button" ? <button className="contact-button mr-2 my-2">Contact</button> : 
        <div className={`nav-link justify-center ${isActive ? activeLinkColor : inactiveLinkColor}`}>{page.navTitle}</div>}
    </div>)
}
 
export default Heading;