import { ReactTyped } from "react-typed";
import { Reveal } from "../Reveal";
import Get_Connected from "../Get_Connected";

type activeFunc = (page: string) => void;
type LandingProps = {
    setActivePageId: activeFunc;
}

const Landing = ({ setActivePageId } : LandingProps) => {
    return (
        <div id="landing" className="h-screen bg-secondary flex flex-col justify-center items-center">
            <div className="biggest-size text-primary font-extrabold shine-effect pb-[2vh]">ELK</div>
            <div className="flex medium-size text-tertiary font-bold pb-[10vh]">
                <ReactTyped className="text-center" strings={["Unlock Automation Testing and CI/CD Expertise"]} typeSpeed={30}/>
            </div>

            <Reveal delay={2}>
                <Get_Connected setActivePageId={(pageId)=>setActivePageId(pageId)}/>
            </Reveal>
            <div id="home-header-holder" className="absolute bottom-0 w-full"></div>
        </div>
    )
}

export default Landing;