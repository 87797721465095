import {Helmet} from "react-helmet";
import { useHeading } from '../HeadingContext';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Reveal } from "../Reveal";

function Arrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
        />
    );
}

const Clients = () => {
    const { HeadingHeight, MinHeight } = useHeading()!;
    // const bg_1 = "/bg_1.jpg";
    const firstLogo = "/ScotiabankLogo.png";
    const secondLogo = "/VirtruLogo.png";
    const thirdLogo = "/TheStarsGroupLogo.png";


    var settings = {
        dots: true,
        infinite: true,
        speed: 750,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        nextArrow: <Arrow className="slick-next" />,
        prevArrow: <Arrow className="slick-prev" />,
      };

    return ( 
        <div className="clients bg-primary" style={{ minHeight: MinHeight }}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Early Learning Kingdom - Clients</title>
                <link rel="canonical" href="http://earlylearningkingdom.com/clients" />
                <meta name="description" content="Clients Page" />
            </Helmet>
            <Reveal>
                <div className="title px-[8vw] pt-[12vh] pb-[8vh]">Our Clients</div>
            </Reveal>
            <div>
                <Reveal>
                    <Slider {...settings}>
                        {/* <div>
                            <div className="flex flex-col lg:flex-row px-16 min-h-[60vh]"> */}
                        <div className="px-[8vw] pb-4">
                            <div className="bg-white flex flex-col lg:flex-row rounded-xl lg:pr-[2vw] shadow-lg min-h-[80vh]">
                                <div className="w-full lg:w-1/3 flex items-center justify-center rounded-t-xl lg:rounded-l-xl lg:rounded-none" style={{ backgroundColor: "#f00404" }}>
                                    <img className="rounded-full object-cover w-60 lg:w-100" src={firstLogo} alt="Scotiabank" />
                                </div>
                                <div className="w-full lg:w-2/3 px-[6vw] lg:px-8 py-[6vh] flex items-center">
                                    <div>
                                        <h2 className="title">Scotiabank - Confirmation Utility Hub</h2>
                                        <ul className="normal-size">
                                            <li>Designed the automation test framework from scratch</li>
                                            <li>Managed regression testing</li>
                                            <li>Generated the extent automation test report</li>
                                        </ul>
                                        <h3 className="subtitle pt-5 pb-0">Tools Used: </h3>
                                        <h4 className="normal-size">IntelliJ IDEA, Java, TestNG, Git, POM</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-[8vw] pb-4">
                            <div className="bg-white flex flex-col lg:flex-row rounded-xl lg:pr-[2vw] shadow-lg min-h-[80vh]">
                                <div className="w-full lg:w-1/3 flex items-center justify-center rounded-t-xl lg:rounded-l-xl lg:rounded-none" style={{ backgroundColor: "#3668ff" }}>
                                    <img className="rounded-full object-cover w-60 lg:w-100" src={secondLogo} alt="Virtru" />
                                </div>
                                <div className="w-full lg:w-2/3 px-[6vw] lg:px-8 py-[6vh] flex items-center">
                                    <div>
                                        <h2 className="title">Virtue - Vault</h2>
                                        <ul className="normal-size">
                                            <li>Developed and maintained an automation test framework capable of communicating with 3rd party services (e.g. BuildKite, GitHub)</li>
                                            <li>Built and extended the automation test framework for Web/Mobile Hybrid/Mobile App testing 
                                                on iOS and Android</li>
                                        </ul>
                                        <h3 className="subtitle pt-5 pb-0">Tools Used:</h3>
                                        <h4 className="normal-size">Appium, AWS services, BuildKite, Docker with API integration, GitHub, JIRA, SauceLabs, 
                                            Selenium, Slack, SonarCloud, TestRail</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-[8vw] pb-4">
                            <div className="bg-white flex flex-col lg:flex-row rounded-xl lg:pr-[2vw] shadow-lg min-h-[80vh]">
                                <div className="w-full lg:w-1/3 flex items-center justify-center rounded-t-xl lg:rounded-l-xl lg:rounded-none">
                                    <img className="rounded-full object-cover w-60 lg:w-100" src={thirdLogo} alt="The Stars Group" />
                                </div>
                                <div className="w-full lg:w-2/3 px-[6vw] lg:px-8 py-[6vh] flex items-center">
                                    <div>
                                    <h2 className="title">The Stars Group</h2>
                                        <ul className="normal-size">
                                            <li>Designed a python automation test framework for the Core Server Platform capable of:
                                                <ul>
                                                    <li style={{ listStylePosition: 'inside', listStyleType: "none", paddingTop: "0" }}><span className="pr-4">1. </span> posting/receiving messages between client/server based on user-defined pycl API</li>
                                                    <li style={{ listStylePosition: 'inside', listStyleType: "none", paddingTop: "0" }}><span className="pr-3">2. </span> verifying the log markers/entries on the log system</li>
                                                    <li style={{ listStylePosition: 'inside', listStyleType: "none", paddingTop: "0" }}><span className="pr-3">3. </span> calling SQL to verify data from DB2 database</li>
                                                </ul>
                                            </li>
                                            <li>Created Jenkins daily smoke test pipeline and weekly regression test pipeline, while integrating Allure reports</li>
                                            <li>Established a python automation test framework based on UI testing for the MarTechEndpoint Restart Checklist Test</li>
                                        </ul>
                                        <h3 className="subtitle pt-5 pb-0">Tools Used: </h3>
                                        <h4 className="normal-size">Pytest, Python API, DB2, Jenkins, Allure, Grafana, TestNG with BDD, Github, JIRA, Selenium, Slack, TestRail</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </Reveal>
            </div>
            <div className="h-10"></div>
            {/* <div className="title px-20 pt-20 pb-16">Our Clients</div>
            
            <div className="flex flex-col lg:flex-row relative py-16 px-10 bg-white shadow-top-down z-40">
                <div className="flex-1 flex items-center justify-center pb-10 lg:pb-0 lg:order-2">
                    <img className="rounded-full object-cover w-60 lg:w-100" src={secondLogo} alt="the stars group logo" />
                </div>
                <div className="flex-1 lg:order-1">
                    <h2 className="title">Vault</h2>
                    <li>Designed/built the automation test framework</li>
                    <li>Maintained the framework to optimize and improve its reusability, reliability, and execution</li>
                    <li>Cooperated with the DevOps team within an agile scrum context to design and
                        implement a framework that could effectively communicate
                        with 3rd party services (e.g. BuildKite, GitHub)</li>
                    <li> Built and extended the automation test framework for Web/Mobile Hybrid/Mobile App testing 
                        (on both iOS and Android)</li>
                    <h3 className="subtitle pt-5 pb-0">Tools Used:</h3>
                    <h4>Appium, AWS services, BuildKite, Docker with API integration, GitHub, JIRA, SauceLabs, 
                        Selenium, Slack, SonarCloud, TestRail</h4>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row py-16 px-10">
                <div className="flex-1 flex items-center justify-center pb-10 lg:pb-0">
                    <img className="rounded-full object-cover w-60 lg:w-100" src={thirdLogo} alt="virtru logo" />
                </div>
                <div className="flex-1">
                    <h2 className="title">The Stars Group</h2>
                    <li>Designed and built a python automation test framework based on pytest for the Core Server Platform capable of:
                        <ul>
                            <li>posting/receiving messages between client/server based on user-defined pycl API</li>
                            <li>verifying the log markers/entries on the log system</li>
                            <li>calling SQL to verify data from DB2 database</li>
                        </ul>
                    </li>
                    <li>Created Jenkins daily smoke test pipeline and weekly regression test pipeline, while integrating Allure reports</li>
                    <li>Designed and built a python automation test framework based on UI testing for the MarTechEndpoint Restart Checklist Test</li>
                    <h3 className="subtitle pt-5 pb-0">Tools Used: </h3>
                    <h4>Pytest, Python API, DB2, Jenkins, Allure, Grafana, TestNG with BDD, Github, JIRA, Selenium, Slack, TestRail</h4>
                </div>
            </div> */}
            <div id="contact-header-holder" className="absolute bottom-0 w-full"
                style={{ height: HeadingHeight }}>
            </div>
        </div>
     );
}
 
export default Clients;