import { memo, FC } from 'react';

interface IFrameProps {
    src: string;
    title: string;
}

const IFrame: FC<IFrameProps> = memo(({ src, title }) => {
    console.log("yo");
    return (
        <div className="video-player flex justify-center">
            <div className="w-[75vw]" style={{ aspectRatio: "1440/836" }}>
                <iframe
                    className="embeddedObject shadow-2xl resizable rounded-lg w-full h-full"
                    name="embedded_content"
                    scrolling="no"
                    style={{ overflow: "hidden" }}
                    src={src}
                    title={title}
                    allowFullScreen
                />
            </div>
        </div>
    );
});

export default IFrame;