import { useState, createContext, useContext } from 'react';

interface HeadingContextType {
    HeadingHeight: number;
    MinHeight: string;
    setHeadingHeight: (height: number) => void;
    setMinHeight: (height: string) => void;
}

// Initialize the context with a default value
export const HeadingContext = createContext<HeadingContextType | undefined>(undefined);

// Easy access for children to use the values
export function useHeading() {
    return useContext(HeadingContext);
}

export function HeadingProvider({ children }: { children: React.ReactNode }) {
    const [HeadingHeight, setHeadingHeight] = useState(0);
    const [MinHeight, setMinHeight] = useState('100vh');

    return (
        <HeadingContext.Provider value={{ HeadingHeight, MinHeight, setHeadingHeight, setMinHeight }}>
            {children}
        </HeadingContext.Provider>
    );
}