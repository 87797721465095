import { motion } from "framer-motion";
import { useRef } from "react";
import { PageInfo } from '../App';
import { useHeading } from "../HeadingContext";
import { useEffect, useState } from "react";

type PageProps = {
    info: PageInfo;
}
// function variants() {
//     return {
//         unloaded: {
//             opacity: 0,
            
//         },
//         loaded: {
//             opacity: 1,
//             transition: {
//                 type: "ease",
//                 duration: 0.7
//             }
//         }
//     }
// }

export function Page({info}: PageProps) {
    const { HeadingHeight } = useHeading()!;
    const [minHeight, setMinHeight] = useState('100vh');


    useEffect(() => {
        setMinHeight(`${window.innerHeight - HeadingHeight}px`);
    }, [HeadingHeight]);

    let ref = useRef(null);

    return(
    <div
        ref={ref}
        id={info.id}
        className="w-full relative overflow-hidden"
        style={{ minHeight: minHeight}}
        >
        <motion.div
            style={{
                width:"100%",
            }}
            >
                <motion.div
                    className="w-full"
                    initial="unloaded"
                    whileInView="loaded"
                    // variants={variants()}
                >
                    {info.child}
                </motion.div>
        </motion.div>
    </div>
    )
}
    