// import { Link } from "react-router-dom";
// import { useHeading } from './HeadingContext';

type activeFunc = (page: string) => void;
type Get_ConnectedProps = {
    setActivePageId: activeFunc;
}

const Get_Connected = ({ setActivePageId }: Get_ConnectedProps) => {
    // const { HeadingHeight } = useHeading()!;

    return (  
        // <Link to="/contact" className="contact-button mx-4 my-2 py-2 px-4"><button>Get Connected</button></Link>
        <button 
        onClick={()=>{
            document.getElementById("contact-header-holder")?.scrollIntoView({ behavior: 'smooth' });
            setActivePageId("contact")
        }}
        className="contact-button py-3 px-4 huge-size rounded-3xl">Get Connected</button>
        // <Link to="/contact" className="get_connected_button "><h2>Get Connected</h2></Link>
    );
}
 
export default Get_Connected;