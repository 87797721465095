import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHeading } from '../HeadingContext';

import { Reveal } from "../Reveal";

const Contact = () => {
    const { MinHeight } = useHeading()!;
    const [sendEnabled, setSendEnabled] = useState(false);
    const form = React.createRef<HTMLFormElement>();

    const send = "/send.png";

    const onChange = () => {
        // setCaptchaDone(true);
        (document.getElementById("send-email-btn") as HTMLButtonElement)!.disabled = false;
        setSendEnabled(true);
    }

    const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const sendBtn = (document.getElementById("send-email-btn") as HTMLButtonElement)!;
        
        sendBtn.disabled = true;
        setSendEnabled(false);

        emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE_ID!, process.env.REACT_APP_EMAIL_TEMPLATE_ID!, form.current!, process.env.REACT_APP_EMAIL_PUBLIC_KEY!)
        .then(() => {
            (e.target as HTMLFormElement).reset();
            toast.success("Email sent!");
        }, () => {
            toast.error("Failed to send email. Please try again later.");
        });

        setTimeout(() => {
            sendBtn.disabled = false;
            setSendEnabled(true);
        }, 2000);
    };
    

    return (
        <div className='contact bg-dark_blue' style={{ minHeight: MinHeight }}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Early Learning Kingdom - Contact</title>
                <link rel="canonical" href="http://earlylearningkingdom.com/contact" />
                <meta name="description" content="Contact Page" />
            </Helmet>
            <div className="normal-text  ">
                <div className='flex justify-center lg:justify-start font-medium'>
                    <div className="flex-2 px-8 xs:px-[10vw]">
                        {/* <h1 className='pb-[6vh] pt-[12vh] md:pt-[16vh] text-white font-bold pop-size'>Enhance your team today...</h1> */}
                        <Reveal>
                            <h1 className='pb-[6vh] pt-[12vh] md:pt-[16vh] text-white font-bold pop-size'>Enhance your team today...</h1>
                        </Reveal>
                        <Reveal>
                            <h2 className="normal-text text-white font-medium">Our team is ready to help you. Email us to get connected:</h2>
                        </Reveal>
                        <Reveal>
                            <form className="bg-primary rounded-xl shadow-top-down p-6" ref={form} onSubmit={sendEmail}>
                                <h2>Name</h2>
                                <input className='shadow-inset rounded-md w-full' type="text" name="from_name" />
                                <h2>Email</h2>
                                <input className='shadow-inset rounded-md w-full' type="email" name="from_email" />
                                <h2>Message</h2>
                                <textarea className='shadow-inset rounded-md w-full h-24 text-xs' name="message" />
                                <div className='captcha absolute'>
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                                        onChange={onChange}
                                    />
                                </div>
                                <input className={`w-full mt-10 mobile:mt-11 md:mt-14 ${sendEnabled ? "demo-button rounded-lg cursor-pointer" : "bg-demo_hover text-white font-bold shadow-lg rounded-lg py-1.5 px-6"}`} 
                                type="submit" id="send-email-btn" value="Send" disabled/>
                            </form>
                        </Reveal>
                        {/* <div style={{ width: "100%"}}>
                            <div className='captcha' style={{ width: "50%", transform: "scale(0.77)", WebkitTransform: "scale(0.77)", transformOrigin: "0 0", WebkitTransformOrigin: "0 0" }}>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                                    onChange={onChange}
                                />
                            </div>
                        </div> */}
                    </div>
                    <Reveal hidden={{ opacity: 0, x: -100, y: 200, rotate: -25, filter: 'blur(10px)' }} visible={{ opacity: 1, x: 0, y: 0, rotate: 0, filter: 'blur(0px)' }}>
                        <div className='hidden sm:flex flex-1 pr-10 pt-20 justify-center'>
                            <img className="object-contain h-48 lg:w-96 unselectable" src={send} alt=""/>
                        </div>
                    </Reveal>
                </div>
            </div>
            <ToastContainer />
        </div> 
     );
}
 
export default Contact;