// import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HeadingProvider } from "./HeadingContext";

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  // <React.StrictMode>
    <HeadingProvider>
      <App />
    </HeadingProvider>
  // </React.StrictMode>
);

reportWebVitals(console.log); // Pass a function that logs the performance results

