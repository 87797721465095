import { Page } from "./pages/Page";
import Heading from "./Heading";
import Landing from "./pages/Landing";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Clients from "./pages/Clients";
import Contact from "./pages/Contact";
import { useState, useEffect, ReactElement } from "react";
import { useScroll, useMotionValueEvent } from "framer-motion";
import { useHeading } from "./HeadingContext";


export type PageInfo = {
    heading: string,
    navTitle: string,
    child: ReactElement<any, any>,
    id: string
}

function getActivePage(pct: number, pages: PageInfo[], headingHeight: number){
    // var h = document.documentElement, 
    // b = document.body

    const totalScrollable = document.documentElement.getBoundingClientRect().height + headingHeight - document.documentElement.clientHeight;

    var curPct = 0
    
    for(var i of pages){
        // Percentage of totalScrollable from each page
        var pctOfTotal = (document.getElementById(i.id)?.getBoundingClientRect().height ?? 0) / totalScrollable;

        // Add percentage of scroll taken up by heading
        if (i.id == "home") {
            var headingPct = headingHeight / totalScrollable;
            curPct += headingPct;
        }

        // Updating the upper bound for a scrollY to be within a page's range
        curPct += pctOfTotal;

        // Without the 0.0002, activePage is valid for user scrolling, but
        // not for the induced scrolling by the getToPage function
        if(pct < curPct- 0.0002){ 
            return i.id
        }
    }
    return pages[pages.length - 1].id;
}

function App() {
    const { HeadingHeight } = useHeading()!;
    const { scrollYProgress } = useScroll();

    const [activePageId, setActivePageId] = useState<string>();

    const pages: PageInfo[] = [
        {heading: "Landing", navTitle: "Landing", id:"landing", child: <Landing setActivePageId={(pageId)=>setActivePageId(pageId)}/>},
        {heading: "Home", navTitle: "Home", id:"home", child: <Home/>},
        {heading: "Services", navTitle: "Services", id:"services", child: <Services/>},
        {heading: "Clients", navTitle: "Clients", id:"clients", child: <Clients/>},
        {heading: "Contact", navTitle: "Contact", id:"contact", child: <Contact/>}
      ];

    useEffect(()=>{
        var h = document.documentElement, 
        b = document.body
        
        var percent = (h.scrollTop||b.scrollTop) - document.documentElement.clientHeight / ((h.scrollHeight||b.scrollHeight) - h.clientHeight);
        percent = Math.min(0.99, percent);

        percent = Math.max(0, percent);
        setActivePageId(getActivePage(percent, pages, HeadingHeight))
    },[])

    // Called whenever user scrolls
    // latest is the ratio of current scrollY over total scrollable Y
    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        const activePage = getActivePage(latest, pages, HeadingHeight);
        setActivePageId(activePage)
    })


    return (
        <div>
            <Landing setActivePageId={(pageId)=>setActivePageId(pageId)}/>
            <div>
                <Heading allPages={pages} activePageId={activePageId!} setActivePageId={(pageId)=>setActivePageId(pageId)}/>
                <div className="w-full relative" style={{ top: HeadingHeight }}>
                    {pages.slice(1).map((page) => <Page key={page.navTitle} info={page}/>)}
                </div>
            </div>
        </div>
    );
}

export default App;
