import { Helmet } from "react-helmet";
import { useHeading } from '../HeadingContext';
// import { useEffect, useRef, useState } from "react";

import { Reveal } from "../Reveal";


const Home = () => {
    const { HeadingHeight, MinHeight } = useHeading()!;
    const question_1 = "/question_1.png";
    const answer_1 = "/answer_1.png";
    const question_2 = "/question_2.png";
    const answer_2 = "/answer_2.png";

    // const [isVisible, setIsVisible] = useState(false);
    // const elementRef = useRef(null);

    // useEffect(() => {
    //     const observer = new IntersectionObserver(entries => {
    //         entries.forEach(entry => {
    //             setIsVisible(entry.isIntersecting);
    //         });
    //     });

    //     if (elementRef.current) {
    //         observer.observe(elementRef.current);
    //     }

    //     return () => {
    //     if (elementRef.current) {
    //         observer.unobserve(elementRef.current);
    //     }
    //     };
    // }, []);

    return (  
        <div className="home bg-primary" style={{ minHeight: MinHeight }}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Early Learning Kingdom - Home</title>
                <link rel="canonical" href="http://earlylearningkingdom.com/home" />
                <meta name="description" content="Home Page" />
            </Helmet>
            {/* <div className="banner font-bold text-center text-5xl text-secondary
            bg-gradient-to-t from-tertiary to-primary py-4 font-montserrat z-20">
                <h1>ELK</h1>
            </div> */}
            <div className="speeches" style={{ minHeight: MinHeight }}>
                <div className="flex flex-col px-[2vw] mobile:px-[4vw] px-[8vw] py-[10vh]">
                    <Reveal hidden={{ opacity: 0, x: -50, y: 100, rotate: 5, filter: 'blur(10px)' }} visible={{ opacity: 1, x: 0, y: 0, rotate: 0, filter: 'blur(0px)' }}>
                        <div className="flex justify-start h-20 mobile:h-30 sm:h-40 xl:h-60">
                            <div className="flex justify-center items-center">
                                <img className="object-contain unselectable h-20 mobile:h-24 sm:h-36 lg:h-44 xl:h-52" src={question_1} alt=""/>
                                <div className="question absolute">Regression risks?<br />Performance concerns?</div>
                            </div>
                        </div>
                    </Reveal>
                    <Reveal delay={0.35} hidden={{ opacity: 0, x: 50, y: 100, rotate: -5, filter: 'blur(10px)' }} visible={{ opacity: 1, x: 0, y: 0, rotate: 0, filter: 'blur(0px)' }}>
                        <div className="flex justify-end h-16 mobile:h-24 sm:h-40 mb-6 mobile:pb-0">
                            <div className="flex justify-center items-center">
                                <img className="object-contain unselectable h-16 mobile:h-20 sm:h-28 lg:h-36 xl:h-44" src={answer_1} alt=""/>
                                <div className="answer absolute">We got you covered!</div>
                            </div>
                        </div>
                    </Reveal>
                    <Reveal delay={0.45} hidden={{ opacity: 0, x: -50, y: 100, rotate: 5, filter: 'blur(10px)' }} visible={{ opacity: 1, x: 0, y: 0, rotate: 0, filter: 'blur(0px)' }}>
                        <div className="flex justify-start h-20 mobile:h-30 sm:h-40 xl:h-60">
                            <div className="flex justify-center items-center">
                                <img className="object-contain unselectable h-16 mobile:h-20 sm:h-28 lg:h-36 xl:h-44" src={question_2} alt=""/>
                                <div className="question absolute">Cross-platform?</div>
                            </div>
                        </div>
                    </Reveal>
                    <Reveal delay={0.55} hidden={{ opacity: 0, x: 50, y: 100, rotate: -5, filter: 'blur(10px)' }} visible={{ opacity: 1, x: 0, y: 0, rotate: 0, filter: 'blur(0px)' }}>
                        <div className="flex justify-end h-16 mobile:h-24 sm:h-40">
                            <div className="flex justify-center items-center">
                                <img className="object-contain unselectable h-12 mobile:h-20 sm:h-28 lg:h-36 xl:h-44" src={answer_2} alt=""/>
                                <div className="answer absolute">No problem!</div>
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>
            <div id="services-header-holder" className="absolute bottom-0 w-full"
                style={{ height: HeadingHeight }}>
            </div>
        </div>
    );
}
 
export default Home;